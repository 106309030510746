import React from "react";
import { useDropzone } from "react-dropzone";
import firebase, { FirebaseContext } from "src/firebase";
import { FaPlusCircle } from "react-icons/fa";
import { toast } from "react-toastify";
function MyDropzone({
  setFieldValue,
  setIsUploading,
  name,
  answer,
  accept,
  path,
  isUploading,
  onComplete,
  maxSize = 10000000,
  calculateExt = false,
}) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } =
    useDropzone({
      accept: accept,
      multiple: false,
      maxSize,
      onDropRejected: (files) => {
        toast.error(files[0].errors[0].message);
      },
      onDropAccepted: (files) => {
        console.log("files");

        const newPath = calculateExt
          ? path + files[0].name.split(".").pop()
          : path;
        setIsUploading(true);
        firebase
          .storage()
          .ref(newPath)
          .put(files[0], {
            customMetadata: {
              name: files[0].name,
              size: files[0].size,
            },
          })
          .then(() => firebase.storage().ref(newPath).getDownloadURL())
          .then((downloadURL) => {
            console.log("downloadURL", downloadURL);
            setFieldValue(name, {
              name: files[0].name,
              url: downloadURL,
              firestorePath: newPath,
            });
            if (typeof onComplete === "function") {
              onComplete();
            }
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);
            console.log(error);
            toast.error("Upload failed");
          });
      },
      disabled: isUploading,
    });

  return (
    <>
      <div
        {...getRootProps({
          className:
            "dropzone rounded-md border border-dashed border-gray-400 focus:outline-none",
        })}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center p-5 ">
          <div className="text-center">
            {isDragActive ? <p>Drop here ...</p> : <p>Drag 'n' drop here</p>}
          </div>
          <div className="">
            <FaPlusCircle className="w-6 h-6" />
          </div>
        </div>
      </div>
      {isUploading
        ? acceptedFiles.map((file) => (
            <li key={file.path} className="flex items-center">
              <div>{file.path}</div>
              <div className="w-6 h-6 spinner-grow mr-3"></div>
              <div>Uploading</div>
            </li>
          ))
        : null}
      {answer ? (
        <li key={answer.name} className="flex items-center">
          <a href={answer.url} target="_blank" className="text-blue-r1">
            {answer.name}
          </a>
          <div className="ml-3">Uploaded</div>
        </li>
      ) : null}
    </>
  );
}

export default MyDropzone;
