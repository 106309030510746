import { format } from "date-fns";
import React from "react";
import { useMutation } from "@apollo/client";
import MarkdownRender from "./../../MarkdownRender";
import { READ_NOTIFICATION } from "./queries";

const NotificationCard = ({ notification }) => {
  const [read, { loading: marking }] = useMutation(READ_NOTIFICATION);

  return (
    <div className="flex flex-col gap-3 p-4 border rounded-md shadow">
      <div title={notification.subject} className="text-lg font-medium">
        {notification.subject}
      </div>
      <div title={notification.message}>
        <MarkdownRender className="prose-sm prose sm:prose-base">
          {notification.message}
        </MarkdownRender>
      </div>
      <div className="flex justify-between text-sm">
        <div className="">
          {format(new Date(notification.created_at), "Pp")}
        </div>
        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              read({ variables: { id: notification.id } });
            }}
          >
            {marking
              ? "Please wait..."
              : notification.isRead
              ? "Seen"
              : "Mark as read"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default NotificationCard;
