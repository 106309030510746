function Sandbox() {
  return (
    <div className="flex justify-center p-5">
      <div>
        <button
          className="px-5 py-2 text-white bg-indigo-600 rounded-md"
          onClick={() => {
            window.open(
              "https://cloudlabs.palmeto.co.in",
              "Sandbox",
              "height=900,width=1600"
            );
          }}
        >
          Click here to connect to sandbox
        </button>
      </div>
    </div>
  );
}

export default Sandbox;
