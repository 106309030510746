import ReactMarkdown from "react-markdown";
import "katex/dist/katex.min.css";
import { InlineMath, BlockMath } from "react-katex";
import RemarkMathPlugin from "remark-math";
import { MarkdownCodeHighlighter } from "./";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

function MarkdownRender(props) {
  return (
    <ReactMarkdown
      remarkPlugins={[RemarkMathPlugin]}
      rehypePlugins={[rehypeKatex]}
      {...props}
      linkTarget="_blank"
      components={{
        code: MarkdownCodeHighlighter,
      }}
    />
  );
}

export default MarkdownRender;
