import React from "react";
import { MdEdit } from "react-icons/md";

const EditProfileDataCard = ({
  title,
  subtitle,
  icon: Icon,
  onClick,
  key,
  verified,
}) => {
  return (
    <div
      className="rounded-md shadow-sm bg-gray-F8 px-8 flex flex-col w-full py-4 cursor-pointer"
      key={key}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <div className="flex flex-no-wrap justify-between items-center">
        <div className="flex flex-no-wrap items-center w-full">
          <div className="rounded-full">
            <Icon />
            {/* <FiCode /> */}
          </div>
          <div className="ml-3 whitespace-pre-wrap pr-12 w-full">
            <div
              className="font-medium text-lg w-full flex items-center"
              title={title}
            >
              <div className="mr-3 max-w-full text-overflow-dots">{title}</div>

              <div className="flex">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onClick();
                  }}
                >
                  <MdEdit className="w-4 h-4" />
                </button>
              </div>
            </div>
            <div className="font-normal text-sm">{subtitle}</div>
            {!verified && subtitle === "Hacker Rank" ? (
              <div className="font-normal text-xs text-red-600">
                The HackerRank username you entered is not verified, please edit
                and verify the username
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfileDataCard;
