import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

// TODO USE ENV
// var firebaseConfig = {
//   apiKey: "AIzaSyB9duheWGsbppBzkkgD0aWqcH8VArXiC2A",
//   authDomain: "hackerrank-talentio.firebaseapp.com",
//   databaseURL: "https://hackerrank-talentio.firebaseio.com",
//   projectId: "hackerrank-talentio",
//   storageBucket: "hackerrank-talentio.appspot.com",
//   messagingSenderId: "424461774020",
//   appId: "1:424461774020:web:9d8972ea4f464a90656d91",
// };
// var firebaseConfig = {
//   apiKey: "AIzaSyB8kCoCWUSbhcm3vpWXVj8OA6Ev9mfUOpc",
//   authDomain: "vedic.dev",
//   databaseURL: "https://vedic-lms.firebaseio.com",
//   projectId: "vedic-lms",
//   storageBucket: "vedic-lms.appspot.com",
//   messagingSenderId: "892746637741",
//   appId: "1:892746637741:web:00b873de0dc1f42fa04124",
//   measurementId: "G-YH3WRP9RZG",
// };

// var firebaseConfig = {
//   apiKey: "AIzaSyCU9tXVbiY0ABRo2AHHy_voop455GGlh6s",
//   authDomain: "alpha.bloombloom.co",
//   databaseURL: "https://bloombloom-frontend.firebaseio.com",
//   projectId: "bloombloom-frontend",
//   storageBucket: "bloombloom-frontend.appspot.com",
//   messagingSenderId: "1011162910506",
//   appId: "1:1011162910506:web:895224b05cbad31049d853",
//   measurementId: "G-T95XNY5QP4",
// };
var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
// console.log(firebaseConfig, process.env);
const app = firebase.initializeApp(firebaseConfig);
/* Helper */

export const doCreateUserWithEmailAndPassword = (tenantId, email, password) => {
  app.auth().tenantId = tenantId;
  return app.auth().createUserWithEmailAndPassword(email, password);
};

export const doSignInWithMagicLink = (tenantId, email, config) => {
  app.auth().tenantId = tenantId;
  return app.auth().sendSignInLinkToEmail(email, config);
};

export const doSignInWithEmailAndPassword = (tenantId, email, password) => {
  console.log("tenantId", tenantId);
  app.auth().tenantId = tenantId;
  return app.auth().signInWithEmailAndPassword(email, password);
};

export const doSignInWithCustomToken = (tenantId, token) => {
  app.auth().tenantId = tenantId;
  return app.auth().signInWithCustomToken(token);
};

let provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account",
});

export const doSignInWithGoogle = (tenantId) => {
  app.auth().tenantId = tenantId;
  return app.auth().signInWithPopup(provider);
};

export const doSignInWithFacebook = (tenantId) => {
  app.auth().tenantId = tenantId;
  return app.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider());
};

export const doSignInWithTwitter = (tenantId) => {
  app.auth().tenantId = tenantId;
  return app.auth().signInWithPopup(new firebase.auth.TwitterAuthProvider());
};

export const doSignInWithMicrosoft = (tenantId) => {
  app.auth().tenantId = tenantId;
  return app
    .auth()
    .signInWithPopup(new firebase.auth.OAuthProvider("microsoft.com"));
};

export const doSignOut = (tenantId) => {
  // app.auth().tenantId = tenantId;
  return app.auth().signOut();
};

export const doPasswordReset = (tenantId, email) => {
  app.auth().tenantId = tenantId;
  return app.auth().sendPasswordResetEmail(email);
};

export const doPasswordUpdate = (tenantId, password) => {
  app.auth().tenantId = tenantId;
  return app.auth().currentUser.updatePassword(password);
};
// onAuthUserListener = (next, fallback) =>
//   this.auth.onAuthStateChanged((authUser) => {
//     if (authUser) {

//     } else {
//       fallback();
//     }
//   });

export default app;
