import queryString from "query-string";
export const generateEmbedURL = ({ url, type }) => {
  try {
    switch (type) {
      case "youtube":
        const parsed = queryString.parseUrl(url);
        return `https://www.youtube.com/embed/${parsed.query.v}`;

      case "vimeo":
        const path = new URL(url).pathname;
        return `https://player.vimeo.com/video/${path?.split("/")[1]}`;

      default:
        return;
    }
  } catch (error) {
    return "";
  }
};
