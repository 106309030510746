import React from "react";
import { BrowserRouter } from "react-router-dom";
import Apollo from "./apollo";
import { AuthContextProvider } from "src/components/authModal";
import * as serviceWorker from "./serviceWorker";
import "./styles.css";
import { SkeletonTheme } from "react-loading-skeleton";
import * as Sentry from "@sentry/react";
// import OneSignal from "react-onesignal";
import * as FullStory from "@fullstory/browser";
import * as ReactDOMClient from "react-dom/client";

// OneSignal.initialize("7d771f92-80f9-4575-b6f7-6dbed22f9aa5");
// TODO CREATE ANALYTICS URL
// LogRocket.init(process.env.REACT_APP_LOGROCKET_URL);
FullStory.init({ orgId: "147WG3" });
// Sentry.init({
//   dsn:
//     process.env.REACT_APP_SENTRY_URL,
// });
Sentry.init({
  dsn: "https://ce919a3217c74957bece3abc13569387@o469617.ingest.sentry.io/5499363",
});

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

// Initial render: Render an element to the root.
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <SkeletonTheme color="#ddd" highlightColor="#fafafa">
          <Apollo />
        </SkeletonTheme>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
