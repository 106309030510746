import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import {
  AiFillWechat,
  AiOutlineCode,
  AiOutlineCodeSandbox,
  AiOutlineCompass,
  AiOutlineFileText,
  AiOutlineHome,
  AiOutlineSchedule,
  AiFillAudio,
} from "react-icons/ai";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { FaHackerrank, FaBars } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import {
  HiOutlineSupport,
  HiOutlineUserGroup,
  HiOutlineVideoCamera,
} from "react-icons/hi";
import { IoBriefcaseOutline, IoDocumentTextOutline } from "react-icons/io5";
import {
  MdClose,
  MdEvent,
  MdOutlineAssessment,
  MdOutlineGroups,
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import { twMerge } from "tailwind-merge";
import { doSignOut } from "src/firebase";
import FirebaseContext from "src/firebase/context";
import { ImBook } from "react-icons/im";

const Sidebar = forwardRef((props, ref) => {
  const { currentUser } = useContext(FirebaseContext);
  const { width, height } = useWindowSize();
  const isMobileScreen = width <= 768;
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const { pathname: path } = location;
  useImperativeHandle(ref, () => ({
    setOpen,
  }));
  if (currentUser.university.config.hide_sidebar) {
    return null;
  }
  return (
    <div
      className={twMerge(
        `flex flex-col gap-1 transition-all duration-300 ease-in-out bg-white flex-shrink-0 px-4 pb-4 overflow-y-auto`,
        isMobileScreen ? "z-50 fixed h-screen top-0" : "",
        open ? `w-72` : isMobileScreen ? "w-0 overflow-hidden px-0" : "w-24"
      )}
    >
      <div
        className={twMerge(
          "h-16 py-4 flex items-center",
          open ? "justify-between" : "justify-center"
        )}
      >
        {isMobileScreen && open && (
          <button
            className=""
            onClick={() => {
              setOpen((el) => !el);
            }}
          >
            {open ? (
              <MdClose className="w-8 h-8" />
            ) : (
              <FaBars className="w-6 h-6" />
            )}
          </button>
        )}
        {open ? (
          currentUser.university.icon ? (
            <img
              src={currentUser.university.icon}
              className={twMerge(
                "object-contain w-40 h-10",
                isMobileScreen ? "object-right" : "object-left"
              )}
              alt=""
            />
          ) : (
            <div className="flex items-center justify-center w-10 h-10 font-bold bg-gray-300 rounded-full">
              <span>{currentUser.university.name[0].toUpperCase()}</span>
            </div>
          )
        ) : null}
        {!isMobileScreen && (
          <button
            className={`flex gap-2 py-3 px-2 rounded-full justify-center items-center`}
            onClick={() => {
              setOpen((e) => !e);
            }}
          >
            {open ? (
              <BsArrowBarLeft className="w-6 h-6" />
            ) : (
              <BsArrowBarRight className="w-6 h-6" />
            )}
          </button>
        )}
      </div>

      <div className={`flex flex-col gap-1 mt-3`}>
        {[
          {
            pathMatch: "/home/dashboard",
            href: "/home/dashboard",
            icon: AiOutlineHome,
            label: "Dashboard",
            condition: true,
          },
          {
            pathMatch: "/home/tests",
            href: "/home/tests",
            icon: IoDocumentTextOutline,
            label: "Assessments",
            condition: currentUser.university.config.test,
          },
          {
            pathMatch: "/home/test_challenges",
            href: "/home/test_challenges",
            icon: FaHackerrank,
            label: "Global Platform Assessments",
            condition: currentUser.university.config.hackerrank,
          },
          {
            pathMatch: "/home/courses",
            href: "/home/courses",
            icon: ImBook,
            label: "Courses",
            condition: currentUser.university.config.courses,
          },
          {
            pathMatch: "/home/events",
            href: "/home/events",
            icon: MdEvent,
            label: "Events",
            condition: currentUser.university.config.events,
          },
          {
            pathMatch: "/home/challenges",
            href: "/home/challenges",
            icon: AiOutlineFileText,
            label: "Challenges",
            condition: currentUser.university.config.challenges,
          },
          {
            pathMatch: "/home/stats",
            href: "/home/stats/dashboard",
            icon: AiOutlineCompass,
            label: "Explore",
            condition: currentUser.university.config.explore,
          },
          {
            pathMatch: "/home/project_assignments",
            href: "/home/project_assignments",
            icon: MdOutlineAssessment,
            label: "Projects",
            condition: currentUser.university.config.projects_assign,
          },
          {
            pathMatch: "/home/jobs",
            href: "/home/jobs",
            icon: IoBriefcaseOutline,
            label: "Jobs",
            condition: currentUser.university.config.jobs,
          },
          {
            pathMatch: "/home/schedule",
            href: "/home/schedule",
            icon: AiOutlineSchedule,
            label: "Schedule",
            condition: currentUser.university.config.schedule,
          },
          {
            pathMatch: "/home/live_session",
            href: "/home/live_session",
            icon: MdOutlineGroups,
            label: "Live Session",
            condition: currentUser.university.config.live_session,
          },
          {
            pathMatch: "/home/communities",
            href: "/home/communities",
            icon: HiOutlineUserGroup,
            label: "Communities",
            condition: currentUser.university.config.communities,
          },
          {
            pathMatch: "/home/sandbox",
            href: "/home/sandbox",
            icon: AiOutlineCodeSandbox,
            label: "Sandbox",
            condition: currentUser.university.config.sandbox,
          },
          {
            pathMatch: "/home/expert-connect",
            href: "/home/expert-connect",
            icon: AiFillWechat,
            label: "Expert Connect",
            condition: currentUser.university.config.expert_connect,
          },
          {
            pathMatch: "/home/support",
            href: "/home/support",
            icon: HiOutlineSupport,
            label: "Support",
            condition: currentUser.university.config.support_page,
          },
          {
            pathMatch: "/home/ide",
            href: "/home/ide",
            icon: AiOutlineCode,
            label: "IDE",
            condition:
              currentUser.university.config.ide &&
              currentUser.university.config.compiler,
          },
          {
            pathMatch: "/home/AiInterview",
            href: "/home/AiInterview",
            icon: AiFillAudio,
            label: "AI Interview (new)",
            condition: true,
          },
          {
            pathMatch:
              "/home/course/ed2cfc18-0314-4308-95fa-ab67f0069f0c/active",
            href: "/home/course/ed2cfc18-0314-4308-95fa-ab67f0069f0c/active",
            icon: HiOutlineVideoCamera,
            label: "Interviews",
            condition:
              currentUser.university.id ===
              "b760c92e-46ec-43a9-9c6d-3d6cf933564a",
          },
        ].map(({ pathMatch, icon: Icon, label, condition, href }) => {
          return (
            condition && (
              <Link
                className={` flex gap-2 rounded-md ${
                  open ? "py-3 px-3" : "justify-center px-8 py-3 font-bold"
                } ${
                  path.startsWith(pathMatch)
                    ? "bg-blue-200"
                    : "hover:bg-blue-300"
                } items-center ${!open && "flex-col  text-[8px] text-center"}`}
                to={href}
              >
                <Icon className="w-6 h-6 text-gray-600" />
                {label === "AI Interview (new)" ? (
                  <span className="inline-block text-transparent text-md bg-gradient-to-r from-red-500 via-green-500 to-indigo-400 bg-clip-text">
                    {label}
                  </span>
                ) : (
                  <span
                    className={`font-dm-sans font-medium leading-tight  text-black ${
                      !open ? "text-[10px]" : "text-sm"
                    }`}
                  >
                    {label}
                  </span>
                )}
                {}
              </Link>
            )
          );
        })}
      </div>
      <div className={`flex mt-auto ${open ? "" : "flex-col"}`}>
        <button
          className={`py-3 flex gap-2 ${
            open ? "px-3" : "justify-center"
          } items-center mt-auto ${
            !open && "flex-col  text-[8px] text-center"
          }`}
          onClick={() => {
            doSignOut();
          }}
        >
          <GoSignOut className="w-6 h-6" />
          {
            <span className="font-normal leading-tight text-md font-dm-sans">
              Logout
            </span>
          }
        </button>
      </div>
    </div>
  );
});

export default Sidebar;
