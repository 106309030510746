
import React, { useMemo, useState, useEffect } from "react";
import LiveSessionSection from "../dashboard/LiveSessionSection";

import "survey-core/defaultV2.min.css";
import {
  GET_ALL_LIVE_SESSIONS
} from "./queries";

function LiveSession() {
  return (<main className="flex flex-col justify-center w-full pb-16">
    <section
      className="flex justify-center w-full py-8 bg-center bg-cover bg-black-111"
    // style={{ backgroundImage: `url(${eventsHero})` }}
    >
      <div className="container flex items-center justify-between px-5 md:px-12">
        <div className="flex flex-col justify-center w-full text-white">
          <div className="text-3xl font-bold md:text-5xl">
            <span>Live Sessions</span>
          </div>
          <div className="py-5 text-lg ">
            <span>
              Explore all the live sessions available for you to join and engage in real-time learning experiences.
            </span>
          </div>
        </div>
        {/* <div className="hidden my-5 md:block">
        <img
          src={bookIcon}
          alt="placeholder"
          className="transform scale-75"
        />
      </div> */}
      </div>
    </section>

    <section className="container flex flex-col self-center px-5 mt-4 md:px-12">
      <LiveSessionSection queryForSessions={GET_ALL_LIVE_SESSIONS} title="All Live Sessions" />
    </section>
  </main>)
};

export default LiveSession;
