import { useQuery } from "@apollo/client";
import { GET_UNIVERSITY_SUPPORT_PROFILE } from "./query";
import { useContext } from "react";
import { FirebaseContext } from "src/firebase";
import { MdMail } from "react-icons/md";

function SupportPage() {
  const { currentUser } = useContext(FirebaseContext);
  const { data, error } = useQuery(GET_UNIVERSITY_SUPPORT_PROFILE, {
    variables: { id: currentUser.university_id },
  });

  if (!data && !error) {
    return <div>Loading...</div>;
  }
  return (
    <div className="flex justify-center p-5">
      <div className="flex flex-col items-center justify-center w-full gap-5 p-5 bg-white border rounded-md">
        <div>
          <img
            src={data.university_by_pk.icon}
            alt="logo"
            className="object-contain w-64"
          />
        </div>
        <div className="text-2xl">{data.university_by_pk.name}</div>
        <a
          href={`mailto:${data.university_by_pk.support_email}`}
          className="flex items-center gap-2 px-5 py-2 text-white bg-indigo-600 rounded-md"
        >
          <div>
            <MdMail className="w-5 h-5" />
          </div>
          <div>Click here to raise the support ticket</div>
        </a>
      </div>
    </div>
  );
}

export default SupportPage;
