import React, { useState } from "react";
import { AuthModalContext } from "./";
function AuthContextProvider({ children }) {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [authModalState, setAuthModelState] = useState({
    open: false,
    type: 0,
  });

  return (
    <AuthModalContext.Provider
      value={{
        handleAuthModal: setAuthModelState,
        setIsSigningIn: setIsSigningIn,
        authModalState,
        isSigningIn,
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
}

export default AuthContextProvider;
