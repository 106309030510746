import { lazy, Suspense, useContext, useRef } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Header } from "src/components";
import Sidebar from "src/components/Sidebar";

import { FirebaseContext } from "src/firebase";
import Sandbox from "../sandbox";
import ExpertConnect from "../expert_connect";
import SupportPage from "../support";
import LiveSession from "../live_session";

// import LiveSession from "./../live_session/index";
// eslint-disable-next-line
// withTitle function

const Challenges = lazy(() => import("src/pages/challenges"));
const Interview = lazy(() => import("src/pages/ai_Interview"));
const Course = lazy(() => import("src/pages/course"));
const Courses = lazy(() => import("src/pages/courses"));
const Dashboard = lazy(() => import("src/pages/dashboard"));
const Event = lazy(() => import("src/pages/event"));
const Events = lazy(() => import("src/pages/events"));
const Profile = lazy(() => import("src/pages/profile"));
const ProjectAssignments = lazy(() => import("src/pages/project_assignments"));
const Projects = lazy(() => import("src/pages/projects"));
const Settings = lazy(() => import("src/pages/settings"));
const Test = lazy(() => import("src/pages/test"));
const Tests = lazy(() => import("src/pages/tests"));
const LearningPath = lazy(() => import("src/pages/learning-path"));
const TestChallenges = lazy(() => import("src/pages/test_challenges"));
const Badges = lazy(() => import("src/pages/badges"));
const Community = lazy(() => import("src/pages/communities"));
const Jobs = lazy(() => import("src/pages/jobs"));
const Schedule = lazy(() => import("src/pages/schedule"));
const TestChallenge = lazy(() => import("src/pages/test_challenge"));
const OverallHackerrankLeaderboard = lazy(() =>
  import("src/pages/test_challenge/OverallLeaderboard")
);
const IDE = lazy(() => import("src/pages/ide"));
const UserSummary = lazy(() => import("./../summary"));

const Home = (props) => {
  const { currentUser } = useContext(FirebaseContext);
  const location = useLocation();
  const headerRef = useRef();
  const sidebarRef = useRef();
  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-stretch flex-grow h-0">
        {!currentUser.university.isJobPortal && <Sidebar ref={sidebarRef} />}
        <div className="flex flex-col flex-grow overflow-y-auto">
          <Header ref={headerRef} sidebarRef={sidebarRef} />
          <Suspense
            fallback={
              <div className="flex items-center justify-center w-full min-h-[500px] text-2xl font-semibold">
                <div className="w-6 h-6 spinner-grow" />
                <div className="ml-3">Loading</div>
              </div>
            }
          >
            <Routes location={location}>
              <Route
                path={`summary`}
                element={<Navigate to={`summary/${currentUser.id}`} />}
              />
              <Route path={`summary/:user_id`} Component={UserSummary} />
              {/* {currentUser.university.config.certificates ? (
          <Route
            path={`certificates`}
            Component={UserIssuedCertificates}
          />
        ) : null} */}
              {currentUser.university.config.badges && (
                <Route path={`badges`} Component={Badges} />
              )}
              <Route
                path={`dashboard`}
                // Component={Dashboard}
                element={<Dashboard />}
              />
              {/* <Route
          path={`dashboardl`}
          Component={withTitle({
            component: DashboardLegacy,
            title: "Dashboard",
          })}
        /> */}
              {currentUser.university.config.projects_assign ? (
                <Route
                  path={`project_assignments/*`}
                  Component={ProjectAssignments}
                  key={window.location.pathname}
                />
              ) : null}
              {currentUser.university.config.communities ? (
                <Route
                  path={`communities/*`}
                  Component={Community}
                  key={window.location.pathname}
                />
              ) : null}
              {currentUser.university.config.challenges ? (
                <Route
                  path={`challenges/*`}
                  Component={Challenges}
                  key={window.location.pathname}
                />
              ) : null}
              {currentUser.university.config.ide ? (
                <Route
                  path={`ide`}
                  Component={IDE}
                  key={window.location.pathname}
                />
              ) : null}
              {!currentUser.university.config.AiInterview ? (
                <Route
                  path={`AiInterview`}
                  Component={Interview}
                  key={window.location.pathname}
                />
              ) : null}

              {/* <Route
          path={`opportunities/:jobID`}
          Component={JobDescription}
        /> */}
              {currentUser.university.config.courses ? (
                <Route path={`courses`} Component={Courses} />
              ) : null}
              {currentUser.university.config.sandbox ? (
                <Route path={`sandbox`} Component={Sandbox} />
              ) : null}
              {currentUser.university.config.expert_connect ? (
                <Route path={`expert-connect`} Component={ExpertConnect} />
              ) : null}
              {currentUser.university.config.support_page ? (
                <Route path={`support`} Component={SupportPage} />
              ) : null}
              {currentUser.university.config.courses ? (
                <Route path={`learning-path/*`} Component={LearningPath} />
              ) : null}
              {currentUser.university.config.courses ? (
                <Route path={`course/:course_id/*`} Component={Course} />
              ) : null}
              {currentUser.university.config.events ? (
                <Route path={`events`} Component={Events} />
              ) : null}
              {currentUser.university.config.schedule ? (
                <Route path={`schedule`} Component={Schedule} />
              ) : null}
              {currentUser.university.config.live_session ? (
                <Route path={`live_session`} Component={LiveSession} />
              ) : null}
              {currentUser.university.config.events ? (
                <Route path={`event/:event_id`} Component={Event} />
              ) : null}
              {currentUser.university.config.explore ? (
                <Route
                  path={`stats/:type`}
                  element={
                    <Navigate
                      from={`stats/:type`}
                      exact
                      to={`stats/:type/${currentUser.university_id}`}
                    />
                  }
                />
              ) : null}
              {/* {currentUser.university.config.explore ? (
            <Route
              path={`stats/:type/:university_id`}
              Component={Stats}
            />
          ) : null} */}
              {currentUser.university.config.projects_assign ? (
                <Route
                  path={`projects`}
                  element={
                    <Navigate
                      from={`projects`}
                      exact
                      to={`projects/${currentUser.university_id}?page=1`}
                    />
                  }
                />
              ) : null}
              {currentUser.university.config.projects_assign ? (
                <Route path={`projects/:university_id`} Component={Projects} />
              ) : null}
              {currentUser.university.config.hackerrank &&
              currentUser.university.config.hackerrank_overall_leaderboard ? (
                <Route
                  path={`hackerrank/leaderboard`}
                  Component={OverallHackerrankLeaderboard}
                />
              ) : null}
              {currentUser.university.config.hackerrank ? (
                <Route
                  path={`hackerrank/:test_challenge_id/:contest_slug`}
                  Component={TestChallenge}
                />
              ) : null}
              {currentUser.university.config.test ? (
                <Route path={`tests/*`} Component={Tests} />
              ) : null}
              {currentUser.university.config.hackerrank ? (
                <Route path={`test_challenges`} Component={TestChallenges} />
              ) : null}
              {currentUser.university.config.test ? (
                <Route path={`test/:test_id/*`} Component={Test} />
              ) : null}
              {currentUser.university.config.jobs ? (
                <Route path={`jobs/*`} Component={Jobs} />
              ) : null}
              {/* <Route
              path={`opportunities`}
              Component={Opportunities}
              key={window.location.pathname}
            /> */}
              <Route
                path={`profile`}
                element={
                  <Navigate
                    path={`profile`}
                    to={currentUser ? `${currentUser.id}` : `/`}
                    exact
                  />
                }
              />
              <Route path={`profile/:id`} Component={Profile} />
              <Route
                path={`edit`}
                element={
                  <Navigate
                    path={`edit`}
                    to={`/home/edit/profile/aboutyou`}
                    exact
                  />
                }
              />
              <Route
                path={`edit/profile`}
                element={
                  <Navigate
                    path={`edit`}
                    to={`/home/edit/profile/aboutyou`}
                    exact
                  />
                }
              />
              <Route path={`edit/:type/*`} Component={Settings} />
              <Route
                path={`/`}
                element={<Navigate path="/" to={`dashboard`} />}
              />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Home;
