import React, { useContext } from "react";
import { MdClose, MdNotificationsOff } from "react-icons/md";
import { Drawer } from "react-pretty-drawer";
import FirebaseContext from "src/firebase/context";
import NotificationCard from "./NotificationCard";

function Notifications({ open, handleClose, loading, data }) {
  const { currentUser } = useContext(FirebaseContext);

  try {
    return (
      <Drawer
        visible={open}
        // visible={true}
        placement="right"
        width="450px"
        onClose={() => handleClose(false)}
      >
        <div className="p-8 flex flex-col gap-8 h-full">
          <div className="flex justify-between">
            <div className="text-lg font-semibold">Notifications</div>
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleClose(false);
                }}
              >
                <MdClose className="w-6 h-6" />
              </button>
            </div>
          </div>
          <div className="flex flex-col flex-grow h-128 overflow-y-auto gap-5">
            {loading ? (
              <div className="text-center">Loading...</div>
            ) : data.notifications.length > 0 ? (
              data.notifications.map((notification) => {
                return <NotificationCard notification={notification} />;
              })
            ) : (
              <div className="flex flex-col items-center justify-center my-16">
                <div>
                  <MdNotificationsOff className="h-24 w-24" />
                </div>
                <div className="text-lg text-gray-600">No notifications</div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    );
  } catch (error) {
    return null;
  }
}

export default Notifications;
