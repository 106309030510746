import gql from "graphql-tag";

export const GET_UNIVERSITY_SUPPORT_PROFILE = gql`
  query GetUniversitySupportProfile($id: uuid!) {
    university_by_pk(id: $id) {
      id
      support_email
      name
      icon
    }
  }
`;
