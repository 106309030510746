import gql from "graphql-tag";

export const READ_NOTIFICATION = gql`
  mutation MarkNotificationAsRead($id: uuid!) {
    update_notifications_by_pk(
      pk_columns: { id: $id }
      _set: { isRead: true }
    ) {
      id
      message
      isRead
      created_at
      subject
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetUserUnreadPlatformNotifications($id: uuid!) {
    notifications(
      where: {
        mode: { _eq: "platform" }
        isRead: { _eq: false }
        user_id: { _eq: $id }
      }
      order_by: { created_at: desc }
    ) {
      id
      message
      isRead
      created_at
      subject
    }
  }
`;
