import { format, parseISO, isAfter, isBefore, set } from "date-fns";
import React, { useContext, useState, useEffect } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { MdClose, MdLocationPin, MdVideocam } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { TestCard } from "src/components";
import FirebaseContext from "src/firebase/context";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import {
  GET_USER_LIVE_SESSION_DATA,
  MARK_LIVE_SESSION_ATTENDANCE,
  GET_LIVE_SESSION,
  INSERT_FEEDBACK,
} from "./queries";
import Modal from "react-modal";

Modal.setAppElement("#root");

function LiveSessionSection({ queryForSessions = GET_USER_LIVE_SESSION_DATA, title = "Live Sessions" }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);
  const [attendanceCode, setAttendanceCode] = useState("");
  const [selectedSession, setSelectedSession] = useState(null);
  const [configurations, setConfigurations] = useState({});
  const [feedbackResponses, setFeedbackResponses] = useState({});
  const client = useApolloClient();
  const { currentUser } = useContext(FirebaseContext);
  const { data, loading, error, refetch } = useQuery(
    queryForSessions,
    {
      variables: { user_id: currentUser.id },
    }
  );

  useEffect(() => {
    if (data) {
      const newConfigurations = {};
      const newFeedbackResponses = {};


      data.live_session.forEach(session => {
        const sessionId = session.id;


        if (session.enable_candidates_feedback_config) {
          newConfigurations[sessionId] = session.enable_candidates_feedback_config;
        }
        session.live_session_participants.forEach(participant => {
          const attendances = participant.user.live_session_platform_attendances;

          attendances.forEach(attendance => {
            if (attendance.candidate_feedback) {
              const feedbackSessionId = attendance.live_session_id;
              newFeedbackResponses[feedbackSessionId] = attendance.candidate_feedback;
            }
          });
        });
      });

      setConfigurations(newConfigurations);
      setFeedbackResponses(newFeedbackResponses);
    }
  }, [data]);

  if (error) {
    console.log("Dashboard index", error);
    return <p>Error :(</p>;
  }

  const handleMarkAttendance = (session) => {
    setSelectedSession(session);
    setIsModalOpen(true);
  };

  const handleFeedbackSubmissiion = (session) => {
    setSelectedSession(session);
    setIsSurveyModalOpen(true);
  };

  const onSubmitFeedback = async (surveyData) => {
    try {
      if (surveyData) {
        const { data } = await client.mutate({
          mutation: INSERT_FEEDBACK,
          variables: { id: selectedSession.id, feedback: surveyData },
        });

        if (data?.insert_live_session_platform_attendance_one) {
          toast.success("submitted feedback successfully!");
          refetch();
        } else {
          toast.error("Failed to submit feedback.");
        }
      } else {
        toast.error("Invalid ");
      }
    } catch (error) {
      toast.error("Failed.");
    } finally {
      setIsSurveyModalOpen(false);
    }
  };

  const handleSurveyComplete = (sender) => {
    const surveyData = JSON.stringify(sender.data, null, 3);
    onSubmitFeedback(surveyData);
  };

  const SubmitAttendanceCode = async () => {
    try {
      const { data: liveSessionData } = await client.query({
        query: GET_LIVE_SESSION,
        variables: {
          where: {
            id: {
              _eq: selectedSession.id,
            },
          },
        },
      });

      const latestAttendanceCode =
        liveSessionData?.live_session[0]?.attendence_code;

      if (latestAttendanceCode && attendanceCode === latestAttendanceCode) {
        const { data } = await client.mutate({
          mutation: MARK_LIVE_SESSION_ATTENDANCE,
          variables: { id: selectedSession.id, status: "present" },
        });

        if (data?.insert_live_session_platform_attendance_one) {
          toast.success("Attendance marked successfully!");
          refetch();
          setIsModalOpen(false);
          setAttendanceCode("");
        } else {
          toast.error("Failed to mark attendance.");
          setAttendanceCode("");
        }
      } else {
        toast.error("Invalid attendance code.");
        setAttendanceCode("");
      }
    } catch (error) {
      toast.error("Failed to mark attendance.");
      setAttendanceCode("");
    }
  };

  const currentDate = new Date();
  const formatDateTime = (dateTimeString) => {
    try {
      const date = parseISO(dateTimeString);
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };

  const parseTimeString = (timeString) => {
    const today = new Date(); // Get today's date
    const [time, offset] = timeString.split('+'); // Separate time and timezone offset
    const [hours, minutes, seconds] = time.split(':'); // Extract hours, minutes, and seconds

    // Create a new Date object using today's date and the extracted time
    const parsedDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      parseInt(hours),
      parseInt(minutes),
      parseInt(seconds)
    );

    return parsedDate;
  };

  const isSessionCompleted = (start_at, ends_at, start_time, end_time) => {
    const currentDateTime = new Date();

    // Parse the session start and end dates
    const sessionStartDate = parseISO(start_at);
    const sessionEndDate = parseISO(ends_at);

    // Apply the start and end times to the session start and end dates
    const [startHours, startMinutes, startSeconds] = start_time.split(':');
    const [endHours, endMinutes, endSeconds] = end_time.split(':');

    const sessionStartWithTime = set(sessionStartDate, {
      hours: parseInt(startHours),
      minutes: parseInt(startMinutes),
      seconds: parseInt(startSeconds)
    });

    const sessionEndWithTime = set(sessionEndDate, {
      hours: parseInt(endHours),
      minutes: parseInt(endMinutes),
      seconds: parseInt(endSeconds)
    });

    // Check if the current time is after the session end time, meaning the session is completed
    if (isAfter(currentDateTime, sessionEndWithTime)) {
      return true; // Session is completed
    }

    // If the current time is before the session start time, the session has not started yet
    if (isBefore(currentDateTime, sessionStartWithTime)) {
      return false; // Session is not completed
    }

    // If the current time is between the session start and end times, the session is still ongoing
    return false;
  };


  return (
    <>
      {/* <section className="flex flex-col">
        <div className="items-center my-4 mb-6 text-xl font-semibold leading-6 tracking-tight xl:my-8 xl:mb-6">
          <div className="flex items-center">Schedule</div>
        </div>
        <div>
          {loading ? (
            <div className="flex flex-wrap gap-4 px-5 py-5 pb-12 -mx-5">
              {Array(4)
                .fill()
                .map(() => (
                  <TestCard loading={true} />
                ))}
            </div>
          ) : data.schedule.length > 0 ? (
            <>
              <div className="flex flex-wrap gap-4 px-5 py-5 pb-12 -mx-5">
                {data.schedule.map(({ title }, i) => {
                  return (
                    <div
                      className="flex flex-col px-8 pt-0 pb-8 rounded-md bg-blue"
                      style={{ minWidth: "20rem" }}
                    >
                      <div className="flex justify-end w-full pr-3">
                        <div className="px-3 text-sm text-white transform -translate-y-2 bg-gray-600 rounded-md">
                          Today's Schedule
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-black-D2">Topic:</div>
                        <div className="text-lg font-semibold text-white ">
                          {title}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="text-lg text-gray-600">
                Please see the{" "}
                <Link to="/home/schedule">
                  <span className="font-semibold">schedule page</span>
                </Link>{" "}
                for a complete schedule
              </div>
            </>
          ) : (
            <div className="text-lg text-gray-600">
              There is no schedule available for today, please see the{" "}
              <Link to="/home/schedule">
                <span className="font-semibold">schedule page</span>
              </Link>{" "}
              for a complete schedule
            </div>
          )}
        </div>
      </section> */}
      <section className="flex flex-col">
        <div className="items-center my-4 mb-6 text-xl font-semibold leading-6 tracking-tight xl:my-8 xl:mb-6">
          <div className="flex items-center">{title}</div>
        </div>
        <div>
          {loading ? (
            <div className="flex flex-wrap gap-4 px-5 py-5 pb-12 -mx-5">
              {Array(4)
                .fill()
                .map(() => (
                  <TestCard loading={true} />
                ))}
            </div>
          ) : data.live_session.length > 0 ? (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-5 py-5 pb-12 -mx-5">
                {data.live_session.map(
                  (
                    {
                      title,
                      link,
                      start_at,
                      ends_at,
                      start_time,
                      end_time,
                      id,
                      attendence_code,
                      live_session_participants,
                      venue,
                      type,
                      isRecurring
                    },
                    i
                  ) => {
                    const completed = isSessionCompleted(start_at, ends_at, start_time, end_time);
                    return (
                      <div
                        key={id}
                        className={`flex flex-col px-8 pt-0 pb-8 rounded-md ${completed ? "bg-gray-400" : type == "online" ? "bg-blue" : "bg-violet-500"
                          } w-full`}
                      >
                        <div className="flex justify-end w-full pr-3">
                          <div className="px-3 text-sm text-white transform -translate-y-2 bg-orange-600 rounded-md">
                            {completed ? "COMPLETED" : type.toUpperCase()}
                          </div>
                        </div>
                        <div>
                          <div className="text-sm text-black-D2">Class:</div>
                          <div className="text-lg font-semibold text-white">
                            {title}
                          </div>
                          <div className="border-t border-gray-500 my-2"></div>
                          <div className="text-sm flex gap-2 text-white">
                            <div><b>Starts:</b> {formatDateTime(start_at)}</div>
                            <div><b>Ends:</b> {formatDateTime(ends_at)}</div>
                          </div>
                          <div className="mt-2 text-sm text-white">
                            <b>Timings: </b>
                            {format(parseTimeString(start_time), 'hh:mm a')} - {format(parseTimeString(end_time), 'hh:mm a')}
                          </div>

                          {!completed && type == "online" && (
                            <button
                              className="flex justify-center w-full px-8 py-1 mt-3 bg-white rounded-md"
                              onClick={() => {
                                window.open(link, "_blank");
                              }}
                            >
                              <div className="mr-3">
                                <MdVideocam className="w-6 h-6" />
                              </div>
                              <div>{"Join Session"}</div>
                            </button>
                          )}

                          {!completed && type == "offline" && (
                            <div className="flex justify-center w-full px-8 py-1 mt-3 bg-white rounded-md items-center">
                              <div className="mr-3">
                                <MdLocationPin className="w-6 h-6" />
                              </div>
                              <div className="w-56">{"Venue: " + venue}</div>
                            </div>
                          )}
                          {data?.live_session[i]
                            ?.can_candidates_mark_attendence && (
                              <>
                                {!live_session_participants[0]?.user
                                  ?.live_session_platform_attendances?.find(attendance =>
                                    attendance.live_session_id === id &&
                                    attendance.status && // Ensure candidate_feedback is present
                                    new Date(attendance.date).toISOString().split('T')[0] === currentDate.toISOString().split('T')[0] // Check for the correct date
                                  )
                                  ? (
                                    <button
                                      className="flex justify-center w-full px-8 py-1 mt-3 bg-yellow-300 rounded-md"
                                      onClick={() =>
                                        handleMarkAttendance({ id, attendence_code })
                                      }
                                    >
                                      <div>Mark Attendance for {currentDate?.toISOString().split('T')[0]}</div>
                                    </button>
                                  ) : (
                                    <div className="flex justify-center w-full px-8 py-1 mt-3 bg-green-300 rounded-md">
                                      Attendance Marked for {currentDate?.toISOString().split('T')[0]}
                                    </div>
                                  )}
                              </>
                            )}
                          {data?.live_session[i]?.enable_candidates_feedback && (
                            <>
                              <button
                                className="flex justify-center w-full px-8 py-1 mt-3 bg-white rounded-md"
                                onClick={() => handleFeedbackSubmissiion({ id: id })}
                              >
                                <div>
                                  {
                                    !live_session_participants[0]?.user
                                      ?.live_session_platform_attendances?.find(attendance =>
                                        attendance.live_session_id === id &&
                                        attendance.candidate_feedback && // Ensure candidate_feedback is present
                                        new Date(attendance.date).toISOString().split('T')[0] === currentDate.toISOString().split('T')[0] // Check for the correct date
                                      )
                                      ? "Give Session Feedback"
                                      : `Feedback Submitted for ${currentDate.toISOString().split('T')[0]}`
                                  }
                                </div>
                              </button>
                            </>
                          )}

                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          ) : (
            <div className="text-lg text-gray-600">
              No live sessions are currently taking place, please check back
              later
            </div>
          )}
        </div>
      </section >

      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <div className="my-2">Attendance for {currentDate.toISOString().split('T')[0]}</div>
        <h2 className="mb-4 text-xl font-semibold">Enter Attendance Code</h2>
        <input
          type="text"
          required={true}
          value={attendanceCode}
          onChange={(e) => setAttendanceCode(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
          placeholder="Enter attendance code"
        />
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded"
          onClick={SubmitAttendanceCode}
        >
          Submit
        </button>
        <button
          className="px-4 py-2 ml-4 text-black bg-gray-300 rounded"
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
      </Modal>
      <Modal
        isOpen={isSurveyModalOpen}
        onRequestClose={() => setIsSurveyModalOpen(false)}
      >
        <div>
          <header className="flex justify-between p-5">
            <div className="my-2">Feedback for {currentDate.toISOString().split('T')[0]}</div>
            <button
              onClick={() => {
                setIsSurveyModalOpen(false);
              }}
            >
              <MdClose className="w-6 h-6" />
            </button>
          </header>

          <div className="mt-6 ">
            {selectedSession && configurations[selectedSession.id] && (
              !feedbackResponses[selectedSession.id] ? (
                <SurveyComponent
                  json={configurations[selectedSession.id]}
                  onComplete={handleSurveyComplete}
                />
              ) : (
                <SurveyComponentDisplay
                  json={configurations[selectedSession.id]}
                  data={feedbackResponses[selectedSession.id]}
                />
              )
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

const SurveyComponent = ({ json, onComplete }) => {
  const survey = new Model(json);
  survey.onComplete.add(onComplete);
  return <Survey model={survey} />;
};

const SurveyComponentDisplay = ({ json, data }) => {
  const survey = new Model(json);
  useEffect(() => {
    if (data) {
      try {
        survey.data = JSON.parse(data);
      } catch (error) {
        console.error("Error parsing survey data:", error);
      }
    }
    survey.mode = "display";
  }, [survey, data]);
  return <Survey model={survey} />;
};

export default LiveSessionSection;