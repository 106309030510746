import queryString from "query-string";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRowSelect, useTable } from "react-table";
import styled from "styled-components";

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    background-color: "red";
    border-radius: 10px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
          color: "red";
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const CustomTable = ({
  columns: cols,
  tableData,
  selectable,
  submitSelected = () => console.log("Not Passed"),
  clientPagination,
  limit = 15,
  page = 1,
  setSelected = () => console.log("Not defined"),
  selectedRow,
}) => {
  //console.log("Required Data", userData);

  const columns = useMemo(() => cols, [cols]);

  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (selectable) {
          return [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ];
        }
        return [...columns];
      });
    }
  );

  useEffect(() => {
    if (selectedRow.length !== selectedFlatRows.length)
      setSelected(selectedFlatRows);
  }, [selectedFlatRows]);

  if (tableData.length !== 0) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div>
          <Styles>
            <table className="bg-blue-100" {...getTableProps()}>
              <thead className="bg-indigo-600 text-white">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {(clientPagination
                  ? rows.slice((page - 1) * limit, page * limit)
                  : rows
                ).map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td className="text-center" {...cell.getCellProps()}>
                            {cell.value || cell.column.id === "selection"
                              ? cell.render("Cell")
                              : "N/A"}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Styles>
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center m-10 text-lg">
      <p>No Data </p>
    </div>
  );
};

export default CustomTable;
