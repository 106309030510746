import format from "date-fns/format";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
function ChallengeCard({
  name,
  start_at,
  ends_at,
  active,
  id,
  domain,
  is_online,
  creator_logo,
  loading = false,
}) {
  const navigate = useNavigate();
  return (
    <div
      className={`flex rounded-md ${
        loading ? "bg-white" : "bg-black"
      } shadow-r1 text-white hover:shadow-r2 hover:-translate-y-3 transform transition-all duration-300 pt-5 pb-8 px-8 items-end justify-center`}
    >
      <div className="flex flex-col font-semibold w-full h-full">
        <div className="flex flex-col justify-between h-full">
          {/* {is_online && !loading ? (
            <div className="flex items-center">
              <div className="text-green-400 text-3xl leading-3 mr-1">
                <FaCircle className="w-2 h-2 text-green-400" />
              </div>
              <div>
                <span>Online</span>
              </div>
            </div>
          ) : null} */}
          <div>
            {loading ? (
              <div className=" w-12 h-12 mt-5">
                <Skeleton height={60} width={60} />
              </div>
            ) : creator_logo ? (
              <div className="mt-1 w-12 h-12 p-1 rounded-md bg-white">
                <img
                  src={creator_logo}
                  alt="Company Logo"
                  className="w-full h-full"
                />
              </div>
            ) : null}
            {loading ? (
              <div className="w-full mt-5">
                <Skeleton />
              </div>
            ) : (
              <div className="mt-1 text-gray-600 text-base font-base">
                <span>{domain}</span>
              </div>
            )}
            {loading ? (
              <div className="w-full">
                <Skeleton height={40} />
              </div>
            ) : (
              <div
                className=" leading-6 my-2 text-2xl cursor-pointer font-medium"
                onClick={(e) => {
                  navigate(`/home/challenges/${id}`);
                }}
              >
                <span>{name}</span>
              </div>
            )}
          </div>
          <div>
            {loading ? (
              <div className="w-full mt-5">
                <div className="w-16">
                  <Skeleton />
                </div>
                <Skeleton />
              </div>
            ) : (
              <div className="leading-5 mt-5 flex md:flex-col flex-row">
                <div className="text-lg font-medium">
                  <span>{"From "}</span>
                </div>
                <div className="md:hidden">
                  <span>{","}</span>
                </div>
                <div className="text-base ml-1 md:ml-0">
                  <span>{`${format(new Date(start_at), "dd MMMM")} - ${format(
                    new Date(ends_at),
                    "dd MMMM yyyy"
                  )}`}</span>
                </div>
              </div>
            )}
            <div className="mt-5 text-white">
              {loading ? (
                <div className="w-full">
                  <Skeleton height={40} />
                </div>
              ) : (
                <button
                  className=" bg-blue md:font-medium font-semibold text-center items-center w-full rounded md:rounded-md p-2 text-lg cursor-pointer"
                  onClick={(e) => {
                    navigate(`/home/challenges/${id}`);
                  }}
                >
                  View challenge
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChallengeCard;
