import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import { useQuery } from "@apollo/client";
import {
  AiOutlineCompass,
  AiOutlineFileText,
  AiOutlineHome,
  AiOutlineSchedule,
} from "react-icons/ai";
import { FaHackerrank, FaBars } from "react-icons/fa";
import { HiOutlineUserGroup, HiOutlineVideoCamera } from "react-icons/hi";
import { IoBriefcaseOutline, IoDocumentTextOutline } from "react-icons/io5";
import {
  MdEvent,
  MdNotificationsNone,
  MdOutlineAssessment,
} from "react-icons/md";
import { Link, useLocation, useMatch } from "react-router-dom";
import { useWindowSize } from "react-use";
import { FirebaseContext } from "src/firebase";
import { AuthModalContext } from "./../authModal";
import { Notifications, ProfileMenu } from "src/components/Header/components";
import { GET_NOTIFICATIONS } from "src/components/Header/components/queries";

// TODO ASK WHAT TO DO ABOUT HEADER
const Header = forwardRef(({ sidebarRef }, ref) => {
  const match = useMatch("/home/:page");
  const location = useLocation();
  const { pathname: path } = location;
  const [cartOpen, setCartOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { handleAuthModal } = useContext(AuthModalContext);
  const { currentUser } = useContext(FirebaseContext);
  const { width, height } = useWindowSize();
  const isMobileScreen = width <= 768;
  const { loading, data } = useQuery(GET_NOTIFICATIONS, {
    variables: { id: currentUser.id },
  });
  useImperativeHandle(ref, () => ({
    setNotificationsOpen,
    notification_data: data?.notifications ?? [],
  }));
  return (
    <>
      <div className="sticky top-0 z-30 flex items-center justify-around flex-shrink-0 w-full h-16 text-black bg-white shadow">
        <div className="flex items-center justify-between w-full h-full gap-3 px-5">
          <div className="flex items-center h-full gap-3 md:gap-0">
            {!currentUser.university.isJobPortal && (
              <button
                className="cursor-pointer md:hidden"
                onClick={() => {
                  sidebarRef.current.setOpen((el) => !el);
                }}
              >
                <FaBars className="w-6 h-6" />
              </button>
            )}
            <div className="flex items-center gap-2">
              {currentUser.university.icon && (
                <img
                  className="object-contain w-16 h-10"
                  src={currentUser.university.icon}
                  alt="logo"
                />
              )}
              <Link to="/home" className="flex md:w-auto">
                <span className="mr-auto text-lg">
                  {currentUser.university.name}
                </span>
              </Link>
            </div>
          </div>
          {!currentUser.university.isJobPortal &&
          currentUser.university.config.navheadertype === 2 ? (
            <div className="flex flex-no-wrap items-center justify-end h-full font-thin">
              <Link
                to="/home/dashboard"
                className={`focus:outline-none h-full flex justify-center items-center ${
                  match && match.params.page === "dashboard"
                    ? "text-white"
                    : "text-gray-77 hover:text-white"
                }`}
              >
                <span className="mx-5">Dashboard</span>
              </Link>

              {currentUser.university.config.courses ? (
                <Link
                  to="/home/courses"
                  className={`focus:outline-none h-full flex justify-center items-center ${
                    match && match.params.page === "courses"
                      ? "text-white"
                      : "text-gray-77 hover:text-white"
                  }`}
                >
                  <span className="mx-5">Courses</span>
                </Link>
              ) : null}
              {currentUser.university.config.events ? (
                <Link
                  to="/home/events"
                  className={`focus:outline-none h-full flex justify-center items-center ${
                    match && match.params.page === "events"
                      ? "text-white"
                      : "text-gray-77 hover:text-white"
                  }`}
                >
                  <span className="mx-5">Events</span>
                </Link>
              ) : null}
              {currentUser.university.config.challenges ? (
                <Link
                  to="/home/challenges"
                  className={`focus:outline-none h-full flex justify-center items-center ${
                    match && match.params.page === "challenges"
                      ? "text-white"
                      : "text-gray-77 hover:text-white"
                  }`}
                >
                  <span className="mx-5">Challenges</span>
                </Link>
              ) : null}
              {currentUser.university.config.explore ? (
                <Link
                  to="/home/stats/dashboard"
                  className={`focus:outline-none h-full flex justify-center items-center ${
                    match && match.params.page === "stats"
                      ? "text-white"
                      : "text-gray-77 hover:text-white"
                  }`}
                >
                  <span className="mx-5">Explore</span>
                </Link>
              ) : null}
              {currentUser.university.config.projects_assign ? (
                <Link
                  to="/home/projects"
                  className={`focus:outline-none h-full flex justify-center items-center ${
                    match && match.params.page === "projects"
                      ? "text-white"
                      : "text-gray-77 hover:text-white"
                  }`}
                >
                  <span className="mx-5">Projects</span>
                </Link>
              ) : null}
              {currentUser.university.config.jobs ? (
                <Link
                  to="/home/jobs"
                  className={`focus:outline-none h-full flex justify-center items-center ${
                    match && match.params.page === "jobs"
                      ? "text-white"
                      : "text-gray-77 hover:text-white"
                  }`}
                >
                  <span className="mx-5">Jobs</span>
                </Link>
              ) : null}
              {/* {currentUser.university.config.certificates ? (
                <Link
                  to="/home/certificates"
                  className={`focus:outline-none h-full flex justify-center items-center ${
                    match && match.params.page === "certificates"
                      ? "text-white"
                      : "text-gray-77 hover:text-white"
                  }`}
                >
                  <span className="mx-5">Certificates</span>
                </Link>
              ) : null} */}
              {/* {currentUser.university.config.test?:null} */}

              {/* <Link
            to="/home/tests"
            className={`focus:outline-none h-full flex justify-center items-center ${
              match && match.params.page === "tests"
                ? "text-white"
                : "text-gray-77 hover:text-white"
            }`}
          >
            <span className="mx-5">Tests</span>
          </Link> */}

              {/* <Link to="/home/opportunities" className={`focus:outline-none h-full flex justify-center items-center ${match&&match.params.page === "opportunities"?"text-white":"text-gray-77 hover:text-white"}`}>
                <span className="mx-5">Opportunities</span>
              </Link> */}
            </div>
          ) : null}
          {/* <div className="w-full">
            <select className="p-2 bg-white border-gray-400"></select>
          </div> */}
          <div className="flex justify-end">
            <div className="flex flex-no-wrap items-center">
              {/* <div className="items-center hidden mx-4 sm:flex xl:hidden">
              <GoSearch className="w-4 h-4 mt-px fill-current md:h-5 md:w-5 md:mt-0" />
            </div>
            <div className="relative hidden w-64 mx-4 xl:block">
              <div className="absolute inset-y-0 left-0 flex items-center pl-4">
                <GoSearch className="w-4 h-4 text-gray-500 fill-current" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="What are you looking for?"
                  className="box-border w-full p-2 pl-10 pr-5 text-sm border border-white border-solid rounded-md bg-black-111"
                />
              </div>
            </div> */}
              {currentUser ? (
                <div className="flex items-center">
                  {/* <div className="items-center hidden mx-3 text-2xl xl:flex">
                  │
                </div> */}
                  {/* <div className="relative flex flex-col justify-center">
                  <button
                    className="hidden mx-3 mr-1 md:flex"
                    onClick={() => setCartOpen(!cartOpen)}
                  >
                    <FiShoppingCart className="w-4 h-4 md:h-5 md:w-5 " />
                  </button>
                  <Cart open={cartOpen} handleCart={setCartOpen} />
                </div> */}
                  {/* <div className="hidden mr-5 whitespace-no-wrap sm:block">
                    <span className="mr-1 text-white">Learning Credits: </span>
                    <span>0</span>
                  </div> */}
                  <button
                    className="relative flex items-center justify-center w-6 h-6 bg-gray-300 rounded-full md:mx-3 xl:w-10 xl:h-10"
                    onClick={() => setNotificationsOpen(!notificationsOpen)}
                  >
                    <MdNotificationsNone className="w-5 h-5 font-extrabold text-gray-600 fill-current md:h-6 md:w-6 xl:h-6 xl:w-6" />
                    {data?.notifications?.length > 0 && (
                      <div className="absolute top-0 right-0 flex items-center justify-center flex-shrink-0 w-2 h-2 text-xs text-white rounded-md bg-blue"></div>
                    )}
                  </button>
                  <Notifications
                    open={notificationsOpen}
                    handleClose={setNotificationsOpen}
                    loading={loading}
                    data={data}
                  />

                  <ProfileMenu />
                </div>
              ) : (
                <div className="flex">
                  <button
                    className="p-2 px-4 text-white whitespace-no-wrap"
                    onClick={() => handleAuthModal({ open: true, type: 0 })}
                  >
                    Sign in
                  </button>
                  <button
                    className="hidden p-2 px-4 whitespace-no-wrap rounded-md bg-blue md:flex"
                    onClick={() => handleAuthModal({ open: true, type: 1 })}
                  >
                    Sign up
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!currentUser.university.isJobPortal &&
      currentUser.university.config.navheadertype === 1 ? (
        <header className="sticky top-0 z-30 items-center justify-around hidden w-full h-12 px-5 text-white bg-black xl:h-16 xl:flex">
          <div className="flex flex-no-wrap items-center justify-end h-full font-thin">
            <Link
              to="/home/dashboard"
              className={`focus:outline-none h-full flex justify-center items-center ${
                match && match.params.page === "dashboard"
                  ? "text-white"
                  : "text-gray-77 hover:text-white"
              }`}
            >
              <span className="mx-5">Dashboard</span>
            </Link>

            {currentUser.university.config.courses ? (
              <Link
                to="/home/courses"
                className={`focus:outline-none h-full flex justify-center items-center ${
                  match && match.params.page === "courses"
                    ? "text-white"
                    : "text-gray-77 hover:text-white"
                }`}
              >
                <span className="mx-5">Courses</span>
              </Link>
            ) : null}
            {currentUser.university.config.events ? (
              <Link
                to="/home/events"
                className={`focus:outline-none h-full flex justify-center items-center ${
                  match && match.params.page === "events"
                    ? "text-white"
                    : "text-gray-77 hover:text-white"
                }`}
              >
                <span className="mx-5">Events</span>
              </Link>
            ) : null}
            {currentUser.university.config.challenges ? (
              <Link
                to="/home/challenges"
                className={`focus:outline-none h-full flex justify-center items-center ${
                  match && match.params.page === "challenges"
                    ? "text-white"
                    : "text-gray-77 hover:text-white"
                }`}
              >
                <span className="mx-5">Challenges</span>
              </Link>
            ) : null}
            {currentUser.university.config.explore ? (
              <Link
                to="/home/stats/dashboard"
                className={`focus:outline-none h-full flex justify-center items-center ${
                  match && match.params.page === "stats"
                    ? "text-white"
                    : "text-gray-77 hover:text-white"
                }`}
              >
                <span className="mx-5">Explore</span>
              </Link>
            ) : null}
            {currentUser.university.config.projects_assign ? (
              <Link
                to="/home/projects"
                className={`focus:outline-none h-full flex justify-center items-center ${
                  match && match.params.page === "projects"
                    ? "text-white"
                    : "text-gray-77 hover:text-white"
                }`}
              >
                <span className="mx-5">Projects</span>
              </Link>
            ) : null}
            {currentUser.university.config.jobs ? (
              <Link
                to="/home/jobs"
                className={`focus:outline-none h-full flex justify-center items-center ${
                  match && match.params.page === "jobs"
                    ? "text-white"
                    : "text-gray-77 hover:text-white"
                }`}
              >
                <span className="mx-5">Jobs</span>
              </Link>
            ) : null}
            {/* {currentUser.university.config.certificates ? (
              <Link
                to="/home/certificates"
                className={`focus:outline-none h-full flex justify-center items-center ${
                  match && match.params.page === "certificates"
                    ? "text-white"
                    : "text-gray-77 hover:text-white"
                }`}
              >
                <span className="mx-5">Certificates</span>
              </Link>
            ) : null} */}

            {/* {currentUser.university.config.test?:null} */}

            {/* <Link
            to="/home/tests"
            className={`focus:outline-none h-full flex justify-center items-center ${
              match && match.params.page === "tests"
                ? "text-white"
                : "text-gray-77 hover:text-white"
            }`}
          >
            <span className="mx-5">Tests</span>
          </Link> */}

            {/* <Link to="/home/opportunities" className={`focus:outline-none h-full flex justify-center items-center ${match&&match.params.page === "opportunities"?"text-white":"text-gray-77 hover:text-white"}`}>
                <span className="mx-5">Opportunities</span>
              </Link> */}
          </div>
        </header>
      ) : null}
      {!currentUser.university.isJobPortal && isMobileScreen && (
        <div className="flex flex-wrap justify-center gap-3 px-5 py-2 bg-white shadow-md">
          {[
            {
              pathMatch: "/home/dashboard",
              href: "/home/dashboard",
              icon: AiOutlineHome,
              label: "Dashboard",
              condition: true,
            },
            {
              pathMatch: "/home/tests",
              href: "/home/tests",
              icon: IoDocumentTextOutline,
              label: "Assessments",
              condition: currentUser.university.config.test,
            },
            {
              pathMatch: "/home/test_challenges",
              href: "/home/test_challenges",
              icon: FaHackerrank,
              label: "Hacker Rank",
              condition: currentUser.university.config.hackerrank,
            },
            // {
            //   pathMatch: "/home/courses",
            //   href: "/home/courses",
            //   icon: ImBook,
            //   label: "Guides",
            //   condition: currentUser.university.config.courses,
            // },
            {
              pathMatch: "/home/events",
              href: "/home/events",
              icon: MdEvent,
              label: "Events",
              condition: currentUser.university.config.events,
            },
            {
              pathMatch: "/home/challenges",
              href: "/home/challenges",
              icon: AiOutlineFileText,
              label: "Challenges",
              condition: currentUser.university.config.challenges,
            },
            {
              pathMatch: "/home/stats",
              href: "/home/stats/dashboard",
              icon: AiOutlineCompass,
              label: "Explore",
              condition: currentUser.university.config.explore,
            },
            {
              pathMatch: "/home/project_assignments",
              href: "/home/project_assignments",
              icon: MdOutlineAssessment,
              label: "Projects",
              condition: currentUser.university.config.projects_assign,
            },
            {
              pathMatch: "/home/jobs",
              href: "/home/jobs",
              icon: IoBriefcaseOutline,
              label: "Jobs",
              condition: currentUser.university.config.jobs,
            },
            {
              pathMatch: "/home/schedule",
              href: "/home/schedule",
              icon: AiOutlineSchedule,
              label: "Schedule",
              condition: currentUser.university.config.schedule,
            },
            {
              pathMatch: "/home/communities",
              href: "/home/communities",
              icon: HiOutlineUserGroup,
              label: "Communities",
              condition: currentUser.university.config.communities,
            },
            // {
            //   pathMatch: "/home/live_session",
            //   href: "/home/live_session",
            //   icon: MdOutlineGroups,
            //   label: "Live Session",
            //   condition: currentUser.university.config.live_session,
            // },
            {
              pathMatch:
                "/home/course/ed2cfc18-0314-4308-95fa-ab67f0069f0c/active",
              href: "/home/course/ed2cfc18-0314-4308-95fa-ab67f0069f0c/active",
              icon: HiOutlineVideoCamera,
              label: "Interviews",
              condition:
                currentUser.university.id ===
                "b760c92e-46ec-43a9-9c6d-3d6cf933564a",
            },
          ].map(({ pathMatch, icon: Icon, label, condition, href }) => {
            return (
              condition && (
                <Link
                  className={`py-3 flex gap-2 rounded-md px-3 ${
                    path.startsWith(pathMatch)
                      ? "bg-gray-300"
                      : "hover:bg-gray-400"
                  } items-center`}
                  to={href}
                >
                  <Icon className="w-6 h-6" />
                  <span className="text-md">{label}</span>
                </Link>
              )
            );
          })}
        </div>
      )}
    </>
  );
});

export default Header;
